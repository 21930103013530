import {
  externalPaymentProcess,
  externalPaymentRequest,
  getGetPaymentByIdQueryOptions,
} from '@cxnpl/api/payments-creation-and-authorization';
import type {
  MutationFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { ExternalTransaction, ExternalPaymentRequestBody, GetPaymentsParams } from '@cxnpl/api/types';
import { customAxios } from '@cxnpl/api/axios';
import type { PaymentRequest, PaymentsResponse } from './types';

export const getPayments = (params?: GetPaymentsParams, signal?: AbortSignal) => {
  return customAxios<PaymentsResponse>({ url: `/v3/payments`, method: 'GET', params, signal });
};

export const useGetPaymentById = <TData = PaymentRequest, TError = unknown>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<PaymentRequest, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentByIdQueryOptions(
    paymentId,
    options as Parameters<typeof getGetPaymentByIdQueryOptions>[1]
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getExternalPaymentRequestMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<PaymentRequest, TError, { data: ExternalPaymentRequestBody }, TContext>;
}): UseMutationOptions<PaymentRequest, TError, { data: ExternalPaymentRequestBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<PaymentRequest, { data: ExternalPaymentRequestBody }> = (props) => {
    const { data } = props;

    // The type casting here requires verification
    return externalPaymentRequest(data) as unknown as Promise<PaymentRequest>;
  };

  return { mutationFn, ...mutationOptions };
};

/**
 * Make a payment
 */
export const useExternalPaymentRequest = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<PaymentRequest, TError, { data: ExternalPaymentRequestBody }, TContext>;
}) => {
  const mutationOptions = getExternalPaymentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getExternalPaymentProcessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<PaymentRequest | ExternalTransaction, TError, { paymentId: string }, TContext>;
}): UseMutationOptions<PaymentRequest | ExternalTransaction, TError, { paymentId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<PaymentRequest | ExternalTransaction, { paymentId: string }> = (props) => {
    const { paymentId } = props;

    // If payment request requires authorisation, PaymentRequest is returned
    // Otherwise, ExternalTransaction is returned
    return externalPaymentProcess(paymentId) as Promise<PaymentRequest | ExternalTransaction>;
  };

  return { mutationFn, ...mutationOptions };
};

export type ExternalPaymentProcessMutationResult = NonNullable<PaymentRequest | ExternalTransaction>;

export type ExternalPaymentProcessMutationError = unknown;

export const useExternalPaymentProcess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<PaymentRequest | ExternalTransaction, TError, { paymentId: string }, TContext>;
}) => {
  const mutationOptions = getExternalPaymentProcessMutationOptions(options);

  return useMutation(mutationOptions);
};
