'use client';

import { useContext } from 'react';
import {
  PaymentsBuilderServiceContext,
  type PaymentsBuilderServiceContextValue,
} from './PaymentsBuilderServiceContext';

export const usePaymentsBuilderService = (): PaymentsBuilderServiceContextValue => {
  const ctx = useContext(PaymentsBuilderServiceContext);
  if (typeof ctx === 'undefined') {
    throw new Error('The usePaymentsBuilderService hook must be used within a PaymentsBuilderServiceProvider');
  }
  return ctx;
};
