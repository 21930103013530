/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  InternalPaymentDetails,
  InternalTransaction,
  RecipientDetails,
  ScheduledPaymentDetails,
  ScheduledPayments200,
  Uuid,
  ValidatePayment,
  ValidatePaymentRecipient200,
} from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Checks the validity of a payment recipient.

For Australian BSB/Account number recipients, checks that the provided BSB is valid and returns all payment schemes that can be used.

For Australian BPAY recipients, checks that the biller code and customer reference number are both valid.

For Australian PayID recipients, checks that PayID is valid and returns the possible payment schemes, along with a lookup ID.
The lookup ID can be provided to the external payments endpoint when initiating a PayID payment to avoid making additional PayID lookups as daily PayID lookups are limited.
 * @summary Validate a recipient
 */
export const validatePaymentRecipient = (recipientDetails: RecipientDetails, signal?: AbortSignal) => {
  return customAxios<ValidatePaymentRecipient200>({
    url: `/payments/validate-recipient`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: recipientDetails,
    signal,
  });
};

export const getValidatePaymentRecipientMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePaymentRecipient>>,
    TError,
    { data: RecipientDetails },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validatePaymentRecipient>>,
  TError,
  { data: RecipientDetails },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validatePaymentRecipient>>,
    { data: RecipientDetails }
  > = (props) => {
    const { data } = props ?? {};

    return validatePaymentRecipient(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidatePaymentRecipientMutationResult = NonNullable<Awaited<ReturnType<typeof validatePaymentRecipient>>>;
export type ValidatePaymentRecipientMutationBody = RecipientDetails;
export type ValidatePaymentRecipientMutationError = unknown;

/**
 * @summary Validate a recipient
 */
export const useValidatePaymentRecipient = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePaymentRecipient>>,
    TError,
    { data: RecipientDetails },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof validatePaymentRecipient>>,
  TError,
  { data: RecipientDetails },
  TContext
> => {
  const mutationOptions = getValidatePaymentRecipientMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Finds the payment schemes for a given payment configuration.

Requires a list of schemes as an input and filters out ones not allowed for the payment configuration (e.g. payment amount exceeds a scheme or account limit).
A list of schemes that can be used for the given recipient can be found using the POST /payments/validate-recipient endpoint
 * @summary Check schemes available for payment
 */
export const validatePayment = (validatePayment: ValidatePayment, signal?: AbortSignal) => {
  return customAxios<ValidatePayment>({
    url: `/payments/validate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: validatePayment,
    signal,
  });
};

export const getValidatePaymentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePayment>>,
    TError,
    { data: ValidatePayment },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof validatePayment>>, TError, { data: ValidatePayment }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof validatePayment>>, { data: ValidatePayment }> = (
    props
  ) => {
    const { data } = props ?? {};

    return validatePayment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidatePaymentMutationResult = NonNullable<Awaited<ReturnType<typeof validatePayment>>>;
export type ValidatePaymentMutationBody = ValidatePayment;
export type ValidatePaymentMutationError = unknown;

/**
 * @summary Check schemes available for payment
 */
export const useValidatePayment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePayment>>,
    TError,
    { data: ValidatePayment },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof validatePayment>>, TError, { data: ValidatePayment }, TContext> => {
  const mutationOptions = getValidatePaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Initiates a transfer between two accounts owned by the same entity.
 * @summary Transfer between accounts
 */
export const internalTransfer = (internalPaymentDetails: InternalPaymentDetails, signal?: AbortSignal) => {
  return customAxios<InternalTransaction>({
    url: `/payments/internal`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: internalPaymentDetails,
    signal,
  });
};

export const getInternalTransferMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof internalTransfer>>,
    TError,
    { data: InternalPaymentDetails },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof internalTransfer>>,
  TError,
  { data: InternalPaymentDetails },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof internalTransfer>>, { data: InternalPaymentDetails }> = (
    props
  ) => {
    const { data } = props ?? {};

    return internalTransfer(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InternalTransferMutationResult = NonNullable<Awaited<ReturnType<typeof internalTransfer>>>;
export type InternalTransferMutationBody = InternalPaymentDetails;
export type InternalTransferMutationError = unknown;

/**
 * @summary Transfer between accounts
 */
export const useInternalTransfer = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof internalTransfer>>,
    TError,
    { data: InternalPaymentDetails },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof internalTransfer>>,
  TError,
  { data: InternalPaymentDetails },
  TContext
> => {
  const mutationOptions = getInternalTransferMutationOptions(options);

  return useMutation(mutationOptions);
};
export const scheduledPayments = (signal?: AbortSignal) => {
  return customAxios<ScheduledPayments200>({ url: `/payments/scheduled`, method: 'GET', signal });
};

export const getScheduledPaymentsQueryKey = () => {
  return [`/payments/scheduled`] as const;
};

export const getScheduledPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof scheduledPayments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getScheduledPaymentsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scheduledPayments>>> = ({ signal }) =>
    scheduledPayments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scheduledPayments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ScheduledPaymentsQueryResult = NonNullable<Awaited<ReturnType<typeof scheduledPayments>>>;
export type ScheduledPaymentsQueryError = unknown;

export function useScheduledPayments<TData = Awaited<ReturnType<typeof scheduledPayments>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useScheduledPayments<
  TData = Awaited<ReturnType<typeof scheduledPayments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useScheduledPayments<
  TData = Awaited<ReturnType<typeof scheduledPayments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useScheduledPayments<
  TData = Awaited<ReturnType<typeof scheduledPayments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof scheduledPayments>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getScheduledPaymentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deleteScheduledPayment = (scheduleId: Uuid) => {
  return customAxios<ScheduledPaymentDetails>({ url: `/payments/scheduled/${scheduleId}`, method: 'DELETE' });
};

export const getDeleteScheduledPaymentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteScheduledPayment>>,
    TError,
    { scheduleId: Uuid },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteScheduledPayment>>, TError, { scheduleId: Uuid }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteScheduledPayment>>, { scheduleId: Uuid }> = (
    props
  ) => {
    const { scheduleId } = props ?? {};

    return deleteScheduledPayment(scheduleId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteScheduledPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteScheduledPayment>>>;

export type DeleteScheduledPaymentMutationError = unknown;

export const useDeleteScheduledPayment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteScheduledPayment>>,
    TError,
    { scheduleId: Uuid },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof deleteScheduledPayment>>, TError, { scheduleId: Uuid }, TContext> => {
  const mutationOptions = getDeleteScheduledPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
