import branding from '@cxnpl/ui/brand';
import type { AllSubscriptionsResponseAllOf, ProcessedSubscriptionEntity } from '@cxnpl/api/types';
import { isOverdraftAccount } from '../../utils/isOverdraftAccount';
import { isLoanAccount } from '../../utils/isLoanAccount';

export const getAccountIconProps = (account: ProcessedSubscriptionEntity & AllSubscriptionsResponseAllOf) => {
  if (isOverdraftAccount(account)) {
    return branding.assets.businessOverdraft;
  } else if (isLoanAccount(account)) {
    return branding.assets.businessLoan;
  } else if (account.productGroup === 'SAVINGS') {
    return branding.assets.savings;
  } else if (account.productGroup === 'CURRENT') {
    return branding.assets.businessTransaction;
  }
  // default - should not happen
  return branding.assets.businessTransaction;
};
