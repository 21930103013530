import currency from 'currency.js';

export const toDisplayAmount = (textRaw: string | undefined | number, addCents?: boolean): string => {
  let amount = textRaw;

  if (typeof textRaw === 'string' && textRaw.at(0) === '$') {
    amount = textRaw.substring(1);
  }

  const text = typeof amount === 'number' ? amount.toString() : amount;
  if (text && isNaN(Number(text.replace(/,/g, '')))) {
    return toDisplayAmount(text.substring(0, text.length - 1));
  }
  const valueFormatted = (text ?? '').replace(/[^\d.]/g, '');
  const decimal = String(valueFormatted).split('.')[1]?.slice(0, 2) ?? (addCents ? '00' : undefined);
  return valueFormatted === '' || isNaN(Number(valueFormatted))
    ? ''
    : currency(Number(valueFormatted.split('.')[0]))
        .format({
          symbol: '',
          decimal: '|',
        })
        .split('|')[0] + (decimal !== undefined ? `.${decimal}` : '');
};

export const toNumberAmount = (text: string | undefined, inCents?: boolean): number => {
  let amount = text;

  if (typeof text === 'string' && text.at(0) === '$') {
    amount = text.substring(1);
  }

  const textFormatted = (amount ?? '').replace(/,/g, '');
  const amountNumber = isNaN(Number(textFormatted)) ? 0 : Number(textFormatted);
  if (inCents) {
    return Math.round(amountNumber * 100);
  }

  return amountNumber;
};

export const toDecimal = (text: string | undefined, options?: { precision?: number }): string | undefined => {
  if (isNaN(Number(text))) {
    return text?.slice(0, -1);
  }
  if (options?.precision) {
    const l = text?.split('.')[1] ?? '';
    if (l.length > options.precision) {
      return text?.slice(0, -1);
    }
  }
  return text;
};
