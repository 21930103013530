/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AuthorizeAndProcessBody,
  ExternalPaymentProcess200,
  ExternalPaymentRequest200,
  ExternalPaymentRequestBody,
  GetPaymentById200,
  GetPaymentsParams,
} from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Initiates a payment to a third party.

Australian BSB/Account number, BPAY, PayID to email, PayID phone number, PayID to ABN, and PayID to organisation ID supported
 * @summary Make a payment
 */
export const externalPaymentRequest = (
  externalPaymentRequestBody: ExternalPaymentRequestBody,
  signal?: AbortSignal
) => {
  return customAxios<ExternalPaymentRequest200>({
    url: `/v3/payments/external/request`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: externalPaymentRequestBody,
    signal,
  });
};

export const getExternalPaymentRequestMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof externalPaymentRequest>>,
    TError,
    { data: ExternalPaymentRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof externalPaymentRequest>>,
  TError,
  { data: ExternalPaymentRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof externalPaymentRequest>>,
    { data: ExternalPaymentRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return externalPaymentRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExternalPaymentRequestMutationResult = NonNullable<Awaited<ReturnType<typeof externalPaymentRequest>>>;
export type ExternalPaymentRequestMutationBody = ExternalPaymentRequestBody;
export type ExternalPaymentRequestMutationError = unknown;

/**
 * @summary Make a payment
 */
export const useExternalPaymentRequest = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof externalPaymentRequest>>,
    TError,
    { data: ExternalPaymentRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof externalPaymentRequest>>,
  TError,
  { data: ExternalPaymentRequestBody },
  TContext
> => {
  const mutationOptions = getExternalPaymentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const externalPaymentProcess = (paymentId: string, signal?: AbortSignal) => {
  return customAxios<ExternalPaymentProcess200>({
    url: `/v3/payments/${paymentId}/external/process`,
    method: 'POST',
    signal,
  });
};

export const getExternalPaymentProcessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof externalPaymentProcess>>,
    TError,
    { paymentId: string },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof externalPaymentProcess>>, TError, { paymentId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalPaymentProcess>>, { paymentId: string }> = (
    props
  ) => {
    const { paymentId } = props ?? {};

    return externalPaymentProcess(paymentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExternalPaymentProcessMutationResult = NonNullable<Awaited<ReturnType<typeof externalPaymentProcess>>>;

export type ExternalPaymentProcessMutationError = unknown;

export const useExternalPaymentProcess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof externalPaymentProcess>>,
    TError,
    { paymentId: string },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof externalPaymentProcess>>, TError, { paymentId: string }, TContext> => {
  const mutationOptions = getExternalPaymentProcessMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPayments = (params?: GetPaymentsParams, signal?: AbortSignal) => {
  return customAxios<unknown>({ url: `/v3/payments`, method: 'GET', params, signal });
};

export const getGetPaymentsQueryKey = (params?: GetPaymentsParams) => {
  return [`/v3/payments`, ...(params ? [params] : [])] as const;
};

export const getGetPaymentsQueryOptions = <TData = Awaited<ReturnType<typeof getPayments>>, TError = unknown>(
  params?: GetPaymentsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentsQueryKey(params);

  const queryFn: QueryFunction = ({ signal }) => getPayments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPaymentsQueryResult = NonNullable<Awaited<ReturnType<typeof getPayments>>>;
export type GetPaymentsQueryError = unknown;

export function useGetPayments<TData = Awaited<ReturnType<typeof getPayments>>, TError = unknown>(
  params: undefined | GetPaymentsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPayments<TData = Awaited<ReturnType<typeof getPayments>>, TError = unknown>(
  params?: GetPaymentsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPayments<TData = Awaited<ReturnType<typeof getPayments>>, TError = unknown>(
  params?: GetPaymentsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPayments<TData = Awaited<ReturnType<typeof getPayments>>, TError = unknown>(
  params?: GetPaymentsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPaymentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const paymentValidation = (paymentId: string, signal?: AbortSignal) => {
  return customAxios<void>({ url: `/v3/payments/${paymentId}/validate`, method: 'GET', signal });
};

export const getPaymentValidationQueryKey = (paymentId: string) => {
  return [`/v3/payments/${paymentId}/validate`] as const;
};

export const getPaymentValidationQueryOptions = <
  TData = Awaited<ReturnType<typeof paymentValidation>>,
  TError = unknown,
>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPaymentValidationQueryKey(paymentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentValidation>>> = ({ signal }) =>
    paymentValidation(paymentId, signal);

  return { queryKey, queryFn, enabled: !!paymentId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof paymentValidation>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type PaymentValidationQueryResult = NonNullable<Awaited<ReturnType<typeof paymentValidation>>>;
export type PaymentValidationQueryError = unknown;

export function usePaymentValidation<TData = Awaited<ReturnType<typeof paymentValidation>>, TError = unknown>(
  paymentId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function usePaymentValidation<TData = Awaited<ReturnType<typeof paymentValidation>>, TError = unknown>(
  paymentId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function usePaymentValidation<TData = Awaited<ReturnType<typeof paymentValidation>>, TError = unknown>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function usePaymentValidation<TData = Awaited<ReturnType<typeof paymentValidation>>, TError = unknown>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof paymentValidation>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getPaymentValidationQueryOptions(paymentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPaymentById = (paymentId: string, signal?: AbortSignal) => {
  return customAxios<GetPaymentById200>({ url: `/v3/payments/${paymentId}`, method: 'GET', signal });
};

export const getGetPaymentByIdQueryKey = (paymentId: string) => {
  return [`/v3/payments/${paymentId}`] as const;
};

export const getGetPaymentByIdQueryOptions = <TData = Awaited<ReturnType<typeof getPaymentById>>, TError = unknown>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentByIdQueryKey(paymentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentById>>> = ({ signal }) =>
    getPaymentById(paymentId, signal);

  return { queryKey, queryFn, enabled: !!paymentId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPaymentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentById>>>;
export type GetPaymentByIdQueryError = unknown;

export function useGetPaymentById<TData = Awaited<ReturnType<typeof getPaymentById>>, TError = unknown>(
  paymentId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentById<TData = Awaited<ReturnType<typeof getPaymentById>>, TError = unknown>(
  paymentId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentById<TData = Awaited<ReturnType<typeof getPaymentById>>, TError = unknown>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPaymentById<TData = Awaited<ReturnType<typeof getPaymentById>>, TError = unknown>(
  paymentId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentById>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPaymentByIdQueryOptions(paymentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deletePayment = (paymentId: string) => {
  return customAxios<void>({ url: `/v3/payments/${paymentId}`, method: 'DELETE' });
};

export const getDeletePaymentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePayment>>, TError, { paymentId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deletePayment>>, TError, { paymentId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePayment>>, { paymentId: string }> = (props) => {
    const { paymentId } = props ?? {};

    return deletePayment(paymentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePaymentMutationResult = NonNullable<Awaited<ReturnType<typeof deletePayment>>>;

export type DeletePaymentMutationError = unknown;

export const useDeletePayment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePayment>>, TError, { paymentId: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deletePayment>>, TError, { paymentId: string }, TContext> => {
  const mutationOptions = getDeletePaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authorizeAndProcess = (paymentId: string, authorizeAndProcessBody: AuthorizeAndProcessBody) => {
  return customAxios<void>({
    url: `/v3/payments/${paymentId}/authorize`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: authorizeAndProcessBody,
  });
};

export const getAuthorizeAndProcessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authorizeAndProcess>>,
    TError,
    { paymentId: string; data: AuthorizeAndProcessBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authorizeAndProcess>>,
  TError,
  { paymentId: string; data: AuthorizeAndProcessBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authorizeAndProcess>>,
    { paymentId: string; data: AuthorizeAndProcessBody }
  > = (props) => {
    const { paymentId, data } = props ?? {};

    return authorizeAndProcess(paymentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthorizeAndProcessMutationResult = NonNullable<Awaited<ReturnType<typeof authorizeAndProcess>>>;
export type AuthorizeAndProcessMutationBody = AuthorizeAndProcessBody;
export type AuthorizeAndProcessMutationError = unknown;

export const useAuthorizeAndProcess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authorizeAndProcess>>,
    TError,
    { paymentId: string; data: AuthorizeAndProcessBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authorizeAndProcess>>,
  TError,
  { paymentId: string; data: AuthorizeAndProcessBody },
  TContext
> => {
  const mutationOptions = getAuthorizeAndProcessMutationOptions(options);

  return useMutation(mutationOptions);
};
