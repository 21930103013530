/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AddPayeeBody, GetPayees200, SavedPayee } from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Creates a new payee tied to the entity
 * @summary Create a new payee
 */
export const addPayee = (addPayeeBody: AddPayeeBody, signal?: AbortSignal) => {
  return customAxios<SavedPayee>({
    url: `/payees`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: addPayeeBody,
    signal,
  });
};

export const getAddPayeeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addPayee>>, TError, { data: AddPayeeBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof addPayee>>, TError, { data: AddPayeeBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addPayee>>, { data: AddPayeeBody }> = (props) => {
    const { data } = props ?? {};

    return addPayee(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddPayeeMutationResult = NonNullable<Awaited<ReturnType<typeof addPayee>>>;
export type AddPayeeMutationBody = AddPayeeBody;
export type AddPayeeMutationError = unknown;

/**
 * @summary Create a new payee
 */
export const useAddPayee = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addPayee>>, TError, { data: AddPayeeBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof addPayee>>, TError, { data: AddPayeeBody }, TContext> => {
  const mutationOptions = getAddPayeeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns all payees associated with the entity
 * @summary Get all payees
 */
export const getPayees = (signal?: AbortSignal) => {
  return customAxios<GetPayees200>({ url: `/payees`, method: 'GET', signal });
};

export const getGetPayeesQueryKey = () => {
  return [`/payees`] as const;
};

export const getGetPayeesQueryOptions = <TData = Awaited<ReturnType<typeof getPayees>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPayeesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayees>>> = ({ signal }) => getPayees(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayees>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPayeesQueryResult = NonNullable<Awaited<ReturnType<typeof getPayees>>>;
export type GetPayeesQueryError = unknown;

export function useGetPayees<TData = Awaited<ReturnType<typeof getPayees>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPayees<TData = Awaited<ReturnType<typeof getPayees>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPayees<TData = Awaited<ReturnType<typeof getPayees>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all payees
 */

export function useGetPayees<TData = Awaited<ReturnType<typeof getPayees>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayees>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPayeesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets a single payee associated with the entity
 * @summary Get a payee
 */
export const getPayee = (payeeId: string, signal?: AbortSignal) => {
  return customAxios<SavedPayee>({ url: `/payees/${payeeId}`, method: 'GET', signal });
};

export const getGetPayeeQueryKey = (payeeId: string) => {
  return [`/payees/${payeeId}`] as const;
};

export const getGetPayeeQueryOptions = <TData = Awaited<ReturnType<typeof getPayee>>, TError = unknown>(
  payeeId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPayeeQueryKey(payeeId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayee>>> = ({ signal }) => getPayee(payeeId, signal);

  return { queryKey, queryFn, enabled: !!payeeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayee>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPayeeQueryResult = NonNullable<Awaited<ReturnType<typeof getPayee>>>;
export type GetPayeeQueryError = unknown;

export function useGetPayee<TData = Awaited<ReturnType<typeof getPayee>>, TError = unknown>(
  payeeId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPayee<TData = Awaited<ReturnType<typeof getPayee>>, TError = unknown>(
  payeeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPayee<TData = Awaited<ReturnType<typeof getPayee>>, TError = unknown>(
  payeeId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a payee
 */

export function useGetPayee<TData = Awaited<ReturnType<typeof getPayee>>, TError = unknown>(
  payeeId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPayee>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPayeeQueryOptions(payeeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deletePayee = (payeeId: string) => {
  return customAxios<void>({ url: `/payees/${payeeId}`, method: 'DELETE' });
};

export const getDeletePayeeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePayee>>, TError, { payeeId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deletePayee>>, TError, { payeeId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePayee>>, { payeeId: string }> = (props) => {
    const { payeeId } = props ?? {};

    return deletePayee(payeeId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePayeeMutationResult = NonNullable<Awaited<ReturnType<typeof deletePayee>>>;

export type DeletePayeeMutationError = unknown;

export const useDeletePayee = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePayee>>, TError, { payeeId: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deletePayee>>, TError, { payeeId: string }, TContext> => {
  const mutationOptions = getDeletePayeeMutationOptions(options);

  return useMutation(mutationOptions);
};
