/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { HandleRedirectParams, LogErrorBody } from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Redirects to specified path in the webapp.
Typically used for embedded banking when trying to provide deep link path to certain resources.
We use a redirect rather than going straight to the webapp as it allows to set cookies if needed.
 */
export const handleRedirect = (params: HandleRedirectParams, signal?: AbortSignal) => {
  return customAxios<void>({ url: `/webapp/embedded-redirect`, method: 'GET', params, signal });
};

export const getHandleRedirectQueryKey = (params: HandleRedirectParams) => {
  return [`/webapp/embedded-redirect`, ...(params ? [params] : [])] as const;
};

export const getHandleRedirectQueryOptions = <TData = Awaited<ReturnType<typeof handleRedirect>>, TError = unknown>(
  params: HandleRedirectParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHandleRedirectQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof handleRedirect>>> = ({ signal }) =>
    handleRedirect(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof handleRedirect>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HandleRedirectQueryResult = NonNullable<Awaited<ReturnType<typeof handleRedirect>>>;
export type HandleRedirectQueryError = unknown;

export function useHandleRedirect<TData = Awaited<ReturnType<typeof handleRedirect>>, TError = unknown>(
  params: HandleRedirectParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHandleRedirect<TData = Awaited<ReturnType<typeof handleRedirect>>, TError = unknown>(
  params: HandleRedirectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHandleRedirect<TData = Awaited<ReturnType<typeof handleRedirect>>, TError = unknown>(
  params: HandleRedirectParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useHandleRedirect<TData = Awaited<ReturnType<typeof handleRedirect>>, TError = unknown>(
  params: HandleRedirectParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof handleRedirect>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHandleRedirectQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Send error log to the backend to help track user errors
 * @summary Webapp error logs
 */
export const logError = (logErrorBody: LogErrorBody, signal?: AbortSignal) => {
  return customAxios<void>({
    url: `/webapp/errorLog`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: logErrorBody,
    signal,
  });
};

export const getLogErrorMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof logError>>, { data: LogErrorBody }> = (props) => {
    const { data } = props ?? {};

    return logError(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LogErrorMutationResult = NonNullable<Awaited<ReturnType<typeof logError>>>;
export type LogErrorMutationBody = LogErrorBody;
export type LogErrorMutationError = unknown;

/**
 * @summary Webapp error logs
 */
export const useLogError = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext> => {
  const mutationOptions = getLogErrorMutationOptions(options);

  return useMutation(mutationOptions);
};
