import type { AllSubscriptionsResponseAllOf, ProcessedSubscriptionEntity } from '@cxnpl/api/types';
import { isAccountOverdrawn } from './isAccountOverdrawn';

export const getOverdraftAccountBalance = (account: ProcessedSubscriptionEntity & AllSubscriptionsResponseAllOf) => {
  const balance =
    ((account.balances.interimAvailable.creditDebitIndicator === 'CREDIT' ? 1 : -1) *
      account.balances.interimAvailable.amount.value) /
    100;
  const overdraftValue = account.loanDetails?.OD?.creditLimit.value ?? 0;
  const accountOverdrawn = isAccountOverdrawn(account);

  return {
    balance,
    overdraftValue,
    isAccountOverdrawn: accountOverdrawn,
  };
};
