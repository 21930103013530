'use client';

import { createContext } from 'react';
import type { ExternalTransaction, InternalTransaction } from '@cxnpl/api/types';
import type { PaymentsFormValues } from 'app/features/payments/paymentsV2/buildPaymentsForms/types';
import type { ShowPaymentFields } from 'app/features/payments/paymentsV2/buildPaymentsForms/updateVisibleFormFields';
import type { PaymentFormProps } from 'app/features/payments/paymentsV2/buildPaymentsForms/updateFormProps';
import type { PaymentRequest } from 'app/api/payments/types';
import type { PaymentTypes, PaymentsBuilderStates } from './types';

export interface PaymentsBuilderServiceContextValue {
  paymentRequestId: string | undefined;
  paymentType: PaymentTypes | undefined;
  paymentsBuilderState: PaymentsBuilderStates;
  paymentValues: Partial<PaymentsFormValues> | undefined;
  startNewPaymentRequest: ({
    type,
    keepExistingInformation,
  }: {
    type: PaymentTypes;
    keepExistingInformation: boolean;
  }) => { newID: string; newPaymentValues: Partial<PaymentsFormValues> };
  patchPaymentsRequest: ({ updatedValues }: { updatedValues: Partial<PaymentsFormValues> }) => void;
  submitFormToPaymentsService: (currentPaymentValues: Partial<PaymentsFormValues> | undefined) => Promise<void>;
  paymentFormProps: PaymentFormProps | undefined;
  fieldsToShow: ShowPaymentFields | undefined;
  transactionResults?: InternalTransaction | ExternalTransaction | PaymentRequest;
  submitTransferRequestToBE: (confirmedPaymentFormValues: PaymentsFormValues) => Promise<void>;
  updatePaymentsServiceState: (state: PaymentsBuilderStates) => void;
  executeExternalPaymentToBE: (id: string) => Promise<void>;
  showAnimationStart: boolean;
  playPaymentSuccessAnimation: boolean;
  showExecutionError: boolean;
}

export const PaymentsBuilderServiceContext = createContext<PaymentsBuilderServiceContextValue | undefined>(undefined);
