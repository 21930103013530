import type { AllSubscriptionsResponseAllOf, ProcessedSubscriptionEntity } from '@cxnpl/api/types';
import { isLoanAccount } from './isLoanAccount';
import { isOverdraftAccount } from './isOverdraftAccount';
import { isTermLoanAccount } from './isTermLoanAccount';

export const isAccountOverdrawn = (account: ProcessedSubscriptionEntity & AllSubscriptionsResponseAllOf) => {
  if (isTermLoanAccount(account)) {
    return false;
  }

  if (isOverdraftAccount(account)) {
    const balance =
      ((account.balances.interimAvailable.creditDebitIndicator === 'CREDIT' ? 1 : -1) *
        account.balances.interimAvailable.amount.value) /
      100;
    const overdraftValue = account.loanDetails?.OD?.creditLimit.value ?? 0;
    return balance < -overdraftValue;
  }

  if (!isLoanAccount(account)) {
    return account.balances.interimAvailable.creditDebitIndicator !== 'CREDIT';
  }

  return false;
};
