'use client';

import { Text, YStack, Select, Badge, CurrencyDisplay, XStack, Image } from '@cxnpl/ui';
import type {
  AllSubscriptionsResponse,
  AllSubscriptionsResponseAllOf,
  ProcessedSubscriptionEntity,
} from '@cxnpl/api/types';
import currency from 'currency.js';
import { useTranslation } from 'react-i18next';
import i18n from 'app/i18n/i18n.config';
import { getAccountIconProps } from 'app/features/accounts/AccountDetails/utils/getAccountIconProps';
import { getAvailableCredit, isOverdraftAccount, getOverdraftAccountBalance } from 'app/features/accounts/utils';

export type AccountDetails = ProcessedSubscriptionEntity & AllSubscriptionsResponseAllOf;

// TODO: most of this function should be extracted into a standalone component. The itemToLabel should only return the JSX for the account
export const itemToLabel = (account: AccountDetails | null, showBalance = true) => {
  if (!account) {
    return '';
  }
  const imageSizeRatio = 0.4;

  const { balance, overdraftValue, isAccountOverdrawn } = getOverdraftAccountBalance(account);

  // Create badge for credit limit
  let displayBadge: React.ReactNode = null;
  const availableCredit = getAvailableCredit(account) / 100;
  const formattedAmount = currency(availableCredit).format();

  if (isOverdraftAccount(account)) {
    if (availableCredit === overdraftValue) {
      // Case where none of the overdraft's credit limit has been drawn
      displayBadge = (
        <Badge size="small" appearance="info">
          {i18n.t('accounts.home.overdraftAvailable', {
            amount: formattedAmount,
          })}
        </Badge>
      );
    } else {
      displayBadge = (
        <Badge size="small" appearance="info">
          {i18n.t('accounts.home.overdraftRemaining', {
            amount: formattedAmount,
          })}
        </Badge>
      );
    }
  } else if (isAccountOverdrawn) {
    // Case where the account is unarranged and has been overdrawn
    displayBadge = (
      <Badge size="small" appearance="danger">
        {i18n.t('accounts.home.accountOverdrawn')}
      </Badge>
    );
  }

  const iconProps = getAccountIconProps(account);

  return (
    <YStack
      key={account.accountId}
      width="100%"
      alignItems="flex-start"
      gap="$sm"
      $laptop={{ flexDirection: 'row', alignItems: 'center' }}
      accessibilityLabel={account.accountNumber}
      testID="account-label"
      flexDirection="row"
    >
      <YStack height="100%" justifyContent="center" alignItems="center">
        <Image
          src={iconProps.src}
          width={iconProps.width * imageSizeRatio}
          height={iconProps.height * imageSizeRatio}
          alt=""
        />
      </YStack>
      <YStack flex={1} $laptop={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <YStack>
          <Text variant="bodyMedium" whiteSpace="normal" wordWrap="break-word">
            {account.nickname}
          </Text>
          <Text variant="bodySmall" color="$foreground/app-subdued" marginTop="$space.xs">
            {account.accountNumber}
          </Text>
        </YStack>
        {showBalance ? (
          <YStack>
            <XStack $laptop={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CurrencyDisplay size="lg" appearance={isAccountOverdrawn ? 'danger' : 'default'}>
                {balance}
              </CurrencyDisplay>
            </XStack>
            {displayBadge}
          </YStack>
        ) : null}
        {account.subscriptionState === 'CLOSED' ? (
          <Badge size="small" appearance="neutral">
            {i18n.t('accounts.home.accountClosed')}
          </Badge>
        ) : null}
      </YStack>
    </YStack>
  );
};
export const itemToValue = (account: AccountDetails | null) => {
  return account?.accountId ?? '';
};

export const AccountSelector = ({
  accountId,
  accounts,
  onActiveChange,
  onValueChange,
  showBalance = true,
  disabled = false,
}: {
  accountId: string;
  accounts: AllSubscriptionsResponse;
  onActiveChange?: (value: string) => void;
  onValueChange?: (value: string) => void;
  showBalance?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Select
      testID="account-select"
      size="$lg"
      label={t('documents.chooseAccount')}
      value={accountId}
      items={Object.values(accounts).sort((a, b) => {
        if (a.subscriptionState === b.subscriptionState) {
          return b.createdDate.localeCompare(a.createdDate);
        }
        return a.subscriptionState === 'ACTIVE' ? -1 : 1;
      })}
      itemToLabel={(account) => itemToLabel(account, showBalance)}
      itemToValue={itemToValue}
      onActiveChange={onActiveChange}
      onValueChange={onValueChange}
      disabled={disabled}
      disablePreventBodyScroll
    />
  );
};
