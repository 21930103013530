import type { BRAND } from 'zod';

export const formatPhoneNumber = (countryCode: (string & BRAND<'select'>) | undefined, phoneNumber: string): string => {
  // Check if the country code is 'Australia +61'
  let cleanedNumber = phoneNumber.replace(/\D/g, '');
  // Extract digits from the country code
  const countryCodeDigits = countryCode?.replace(/\D/g, '');
  if (countryCode === 'Australia +61') {
    // Remove any non-digit characters

    // Check if the number starts with the country code
    if (cleanedNumber.startsWith('61')) {
      cleanedNumber = cleanedNumber.substring(2);
    }

    // Remove leading zeros
    cleanedNumber = cleanedNumber.replace(/^0+/, '');

    // Ensure the number has 9 digits (standard for Australian phone numbers)
    if (cleanedNumber.length === 9) {
      return `+61-${cleanedNumber}`;
    }
  } else {
    // Remove the leading country code from the phone number if present
    if (countryCodeDigits) {
      if (cleanedNumber.startsWith(countryCodeDigits)) {
        cleanedNumber = cleanedNumber.substring(countryCodeDigits.length);
      }
    }

    // Remove leading zeros from the cleaned number
    cleanedNumber = cleanedNumber.replace(/^0+/, '');

    // Format the phone number with the country code
    const formattedPhoneNumber = `+${countryCodeDigits}-${cleanedNumber}`;

    return formattedPhoneNumber;
  }

  return phoneNumber;
};
