export interface FrequencyFieldItem {
  label: string;
  value: string;
}

export const frequencyValues: FrequencyFieldItem[] = [
  { label: 'Never', value: 'Never' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Fortnightly', value: 'Fortnightly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Yearly', value: 'Yearly' },
];

export const frequencyItemToLabel = (item: FrequencyFieldItem | null) => {
  if (!item) {
    return '';
  }
  return item.label;
};

export const frequencyItemToValue = (item: FrequencyFieldItem | null) => {
  if (!item) {
    return '';
  }
  return item.value;
};
