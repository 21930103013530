import type { AllSubscriptionsResponseAllOf, ProcessedSubscriptionEntity } from '@cxnpl/api/types';
import currency from 'currency.js';

// Returns result in cents
export const getAvailableCredit = (account: ProcessedSubscriptionEntity & AllSubscriptionsResponseAllOf): number => {
  // Assign balance as either positive or negative
  const balance =
    account.balances.interimAvailable.creditDebitIndicator === 'CREDIT'
      ? account.balances.interimAvailable.amount.value
      : -account.balances.interimAvailable.amount.value;

  // Get allowed credit limit of the account; if it doesn't exist then there's no agreed credit limit
  const limit = currency(account.balances.interimAvailable.creditLimit.amount).value;
  // If the limit balance is less than the balance, there is available credit
  if (-limit < balance) {
    // Maximum amount of available credit is the credit limit itself
    return Math.min(limit + balance, limit);
  }

  // Otherwise, all of the credit limit has been used and the available credit is 0
  return 0;
};

// Returns result in cents
export const getTotalAvailableCredit = (accounts: (ProcessedSubscriptionEntity & AllSubscriptionsResponseAllOf)[]) => {
  return accounts.reduce((acc, a) => acc + getAvailableCredit(a), 0);
};
